import MatrixGallery_TextBottomCustomHeightSkinComponent from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/skinComps/TextBottomCustomHeightSkin/TextBottomCustomHeightSkin.skin';
import MatrixGallery_TextBottomCustomHeightSkinController from '@wix/thunderbolt-elements/src/components/NativeGalleries/MatrixGallery/viewer/MatrixGallery.controller';


const MatrixGallery_TextBottomCustomHeightSkin = {
  component: MatrixGallery_TextBottomCustomHeightSkinComponent,
  controller: MatrixGallery_TextBottomCustomHeightSkinController
};


export const components = {
  ['MatrixGallery_TextBottomCustomHeightSkin']: MatrixGallery_TextBottomCustomHeightSkin
};

